export default {
  // methods: {
  //   hitungImt () {
  //     // alert('asdasda')
  //     // const tinggi = this.data.fisik.status_gizi.tinggi_badan
  //     // const berat = this.data.fisik.status_gizi.berat_badan

  //     // console.log(tinggi)
  //     // console.log(berat)

  //     // var tb = parseFloat(tinggi / 100)
  //     // var bb = berat
  //     // var imt = bb / (tb * tb)

  //     // this.data.fisik.status_gizi.imt = imt.toString()
  //     // console.log(imt)
  //   }
  // },
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      label: 'Ruangan',
      // data: [
      //   { text: 'Laki-Laki', value: 'laki-laki' },
      //   { text: 'Wanita', value: 'wanita' }
      // ],
      data: null,
      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 4
    }
  },
  form1: {
    p1: {
      widget: 'wdatenormal',
      data: null,
      col: 6,
      label: 'Tanggal Pemeriksaan'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Diagnosa Pre Ops'
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Rencana Operasi'
    },
    p4: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: 'Anamnesa'
    },
    l1: {
      widget: 'wlabelkiri',
      data: [],
      col: 12,
      label: 'Riwayat Penyakit :'
    },
    p5: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: 'Hipertensi'
    },
    p6: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: 'Penyakit Ginjal'
    },
    p7: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: 'Penyakit Jantung'
    },
    p8: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: 'Kelainan Saraf'
    },
    p9: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: 'Penyakit Paru'
    },
    p10: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: 'Kelainan Darah'
    },
    p11: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: 'Penyakit Hati'
    },
    p12: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: 'Kelainan Metabolik'
    },
    p13: {
      widget: 'wtext',
      data: null,
      col: 12,
      label: 'Dll'
    },
    p14: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'BB'
    },
    p15: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'TB'
    },
    p16: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Kapan terakhir kambuh:', value: null, field: 'auto' }
      ],
      col: 6,
      label: 'Riwayat Asma'
    },
    p17: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Sebutkan:', value: null, field: 'auto' }
      ],
      col: 6,
      label: 'Riwayat Alergi Obat'
    },
    p18: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya, Letak:', value: null, field: 'auto' }
      ],
      col: 6,
      label: 'Gigi Palsu'
    },
    p19: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: 'Pemeriksaan Fisik'
    },
    l2: {
      widget: 'wlabelkiri',
      data: [],
      col: 12,
      label: 'TTV'
    },
    p20: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'TD', suffix: 'mmHg', type: 'number' } }
    },
    p21: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'Nadi', suffix: 'x/i', type: 'number' } }
    },
    p22: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'RR', suffix: 'x/i', type: 'number' } }
    },
    p23: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'Suhu', suffix: 'C', type: 'number' } }
    },
    p24: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'GCS', type: 'number' } }
    },
    p25: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'VAS', type: 'number' } }
    }
  },
  form2: {
    l1: {
      widget: 'wlabelkiri',
      data: [],
      col: 12,
      label: 'Obat-obat yang sedang digunakan'
    },
    p1: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: ' ',
      property: { attrs: { prefix: '1. ' } }
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: ' ',
      property: { attrs: { prefix: '4. ' } }
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: ' ',
      property: { attrs: { prefix: '2. ' } }
    },
    p4: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: ' ',
      property: { attrs: { prefix: '5. ' } }
    },
    p5: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: ' ',
      property: { attrs: { prefix: '3. ' } }
    },
    p6: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: ' ',
      property: { attrs: { prefix: '6. ' } }
    }
  },
  form3: {
    l1: {
      widget: 'wlabelkiri',
      data: [],
      col: 12,
      label: 'Pemeriksaan Penunjang'
    },
    p1: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: '1. Laboratorium:'
    },
    p2: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: '3. Rontgen Thorax:'
    },
    p3: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: '2. EKG:'
    },
    p4: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: '4. Dll:'
    }
  },
  form4: {
    p1: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Penyulit dan Masalah lain:'
    }
  },
  form5: {
    p1: {
      widget: 'wradio',
      data: [
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '3', value: '3' },
        { text: '4', value: '4' },
        { text: '5', value: '5' },
        { text: 'E', value: 'E' }
      ],
      col: 6,
      label: 'Status Fisik ASA'
    }
  },
  form6: {
    p1: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Rencana anestesi'
    }
  },
  form7: {
    p1: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Obat Premedikasi'
    }
  }
}
